<template>
	<div class="p-3 liff-reservation-confirm">
		<b-card header-tag="header" class="mb-3 liff-reservation-confirm__card">
			<template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="mb-0">預約人資訊</h6>
        </div>
      </template>
			<b-form-group label="姓名" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<!-- <b-form-input v-model="form.name" class="text-right" /> -->
         <div class="text-right s-text-sm">{{ user.name }}</div>
			</b-form-group>
			<b-form-group label="電話" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm">
				<!-- <b-form-input v-model="form.phone" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ user.mobile_phone }}</div>
			</b-form-group>
		</b-card>

		<b-card header-tag="header" class="mb-3 liff-reservation-confirm__card">
			<template #header>
				<div class="d-flex justify-content-between align-items-center">
					<h6 class="mb-0">預約資訊</h6>
				</div>
			</template>
			<b-form-group label="項目名稱" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="service.name">
				<!-- <b-form-input v-model="form.serviceName" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ service.name }}</div>

			</b-form-group>
			<b-form-group label="服務人員" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="staff.name">
				<!-- <b-form-input v-model="form.servicePerson" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ staff.name }}</div>

			</b-form-group>
			<b-form-group label="預約時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<!-- <b-form-input v-model="form.serviceTime" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ moment(schedule.start_at).format('YYYY-MM-DD HH:mm') }}</div>
			</b-form-group>
			<b-form-group label="預約結束時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="schedule.end_at">
				<!-- <b-form-input v-model="form.serviceTime" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ moment(schedule.end_at).format('YYYY-MM-DD HH:mm') }}</div>
			</b-form-group>
			<b-form-group label="服務地點" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="branch.name">
				<!-- <b-form-input v-model="form.serviceLocation" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ branch.name }}</div>

			</b-form-group>
		</b-card>

		<div class="mb-3">
			<DynamicForm
        ref="dynamicForm"
        :input-subjects="fields"
        v-model="dynamicFormInput"
        @file-uploading="updateFileUploading"
      >
      </DynamicForm>
		</div>

		<div class="d-flex s-space-x-2">
			<SharedButton class="s-btn-outline-primary w-50" @click="prevStep">上一步</SharedButton>
			<SharedButton class="s-btn-bg-primary w-50" @click="done" :disabled="isFileUploading">確認預約</SharedButton>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import SharedButton from '@/components/Page/Liff/Shared/Button.vue';
import DynamicForm from "@/components/DynamicForm/DynamicForm.vue";
import reservationMixin from "@/mixins/liff/reservation";
import bookingApi from '@/apis/liff/v2/booking';

	export default {
		mixins: [reservationMixin],
		components: {
			SharedButton,
			DynamicForm,
		},
		data() {
			return {
				dynamicFormInput: {},
				form: {
					name: '林曉娟',
					phone: '0987654321',
					serviceName: '精緻剪髮、歐系染髮、洗髮',
					servicePerson: 'Nymphia',
					serviceTime: '2024/01/01 09:00',
					serviceLocation: '台北市中山區中山北路二段36巷36號',
					note: '我幫我媽預約，媽媽姓名王美花',
				},
				bookingPreset: {},
        hiddenFields: [],
        isFileUploading: false
			}
		},
		computed: {
			fields() {
				return this.bookingPreset?.config?.fields.filter((field) => {
          return !field?.config?.dashboard_only && !this.hiddenFields.includes(field._id)
				}).map(field => {
          field.id = field?.config?._id || field?._id
          return field
        })
			},
			moment () {
        return moment
      },
      branchId() {
        return this.$route.query.branch_id
      },
		},
		mounted() {
			this.init()
		},
    methods: {
      updateFileUploading(val) {
        this.isFileUploading = val
      },
      setDefaultFieldValue(inputObject) {
        if (this.bookingPreset?.config?.booking_mapping?.bookings?.start_at) {
          inputObject[this.bookingPreset?.config?.booking_mapping?.bookings?.start_at] = this.schedule.start_at;
        }

        if (this.bookingPreset?.config?.branch?.field_id) {
          inputObject[this.bookingPreset?.config?.branch?.field_id] = this.branch.model;
        }

        if (this.bookingPreset?.config?.service?.collection_id) {
          const serviceField = this.bookingPreset?.config?.fields.find((field) => {
            return field.collection_id && field.collection_id == this.bookingPreset?.config?.service?.collection_id
          })
          inputObject[serviceField?._id] = this.service.origin_array;
        }
        return inputObject
      },
      init() {
        this.fetchPreset()
        this.dynamicFormInput = this.otherData
      },
      async fetchPreset() {
        const data = await this.fetchPresetData(this.$route.params.bookingPresetId, this.branchId);
        this.bookingPreset = data
        if (data?.config?.booking_mapping?.bookings?.start_at) {
          this.hiddenFields.push(data.config.booking_mapping.bookings.start_at)
        }

        if (data?.config?.branch?.field_id) {
          this.hiddenFields.push(data?.config?.branch?.field_id)
        }

        if (data?.config?.service?.collection_id) {
          const serviceField = data?.config?.fields.find((field) => {
            return field.collection_id && field.collection_id == data?.config?.service?.collection_id
          })

          this.hiddenFields.push(serviceField?._id)
        }
      },
      prevStep() {
        this.prevStepByList("LiffReservationConfirm", {
          branch_id: this.branchId,
          staff_id: this.staffId
        })
      },
      async doSubmit () {
        const result = await this.$refs.dynamicForm.validate()
        if (result) {
          this.submit()
        }
      },
      async submit() {
        this.isLoading = true
        try {
          const dataToSave = {
            preset_id: this.$route.params.bookingPresetId,
            fields: this.setDefaultFieldValue(this.dynamicFormInput),
            staff_id: this.staff.id,
            branch_id: this.branch.id,
            service_id: this.service.id,
            start_at: this.schedule.start_at,
            end_at: this.schedule.end_at
          }

          this.set('other', this.dynamicFormInput)

          await bookingApi.saveBooking(dataToSave)

          this.$router.push({
            name: 'LiffReservationSuccess'
          })
        } catch (e) {
          console.error(e)
          this.$swal.fire({ title: "送出失敗", type: "error" })
        }
        this.isLoading = false
      },
      done() {
        if (this.isFileUploading) {
          return
        }
        this.doSubmit()
      }
    }
	}

</script>

<style lang="scss" scoped>
.liff-reservation-confirm {
	&__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		.card-header {
			border-radius: 10px 10px 0 0;
			border-bottom: 1px solid #E5E5EA;
			font-size: 16px;
			font-weight: 500;
			padding: 16px 16px 12px 16px;
		}
	}
	&__textarea {
		border-radius: 10px;
		border: 1px solid #636366;
		color: #2C2C2E;
		font-size: 16px;
		line-height: 1.2;
		padding: 16px 12px;
	}

	input {
		font-size: 14px;
		height: auto;
    line-height: 1.2;
		border-bottom: 1px solid #E5E5EA;
    padding-bottom: 6px;
	}
}
</style>
